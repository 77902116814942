import styled from "styled-components";
import { Spacer } from "./Spacer";
import {
  dateFormatterHourMinute,
  dateFormatterWeekDayDayMonthYear,
} from "../../format";
import { TextCapitalized } from "./text/TextCapitalized";
import { colors } from "../../styles/figmaColors";
import { typographies } from "../../styles/figmaTypographies";
import { useTranslation } from "react-i18next";
import { Badge } from "./Tag/Badge";
import { Avatar } from "./Avatar/Avatar";
import { ClearRound } from "../materialUi/materialUi";
import { VehicleCard } from "./VehicleCard";
import { TooltipDS } from "./Tooltip";

export type VehicleType = "SHARED" | "PERSONAL" | "OUT_OF_FLEET";

const LIMIT_VARIATION_PERCENTAGE = 0.15;

export interface Trip {
  secondsDuration: number | null;
  startDate: string;
  endDate: string;
  distance: number | null;
  ecoDrivingScore: number | null;
  smoothnessScore: number | null;
  supSpeedScore: number | null;
  durationVariation: number | null;
  durationVariationPercent: number | null;
  distanceVariation: number | null;
  distanceVariationPercent: number | null;
}

export interface DrivingEventsType {
  smoothnessScore: number | null;
  supSpeedScore: number | null;
}

export interface TripOptionsDisplay {
  distance: number | null;
  distanceVariation: number | null;
  distanceVariationPercent: number | null;
  duration: number | null;
  durationVariation: number | null;
  durationVariationPercent: number | null;
  ecoDrivingScore: number | null;
  drivingEvents: DrivingEventsType;
  driver: {
    firstName: string;
    lastName: string;
  } | null;
}

export interface VehicleInfo {
  registrationNumber: string | null;
  reference: string | null;
  vehicleType: VehicleType;
  model: string | null;
}

export interface DriverInfo {
  lastName: string;
  firstName: string;
  analyticCode1: string | null;
  uuid: string;
}

export interface DayTripDetailsProps {
  date: Date | null;
  vehicle: VehicleInfo | null;
  trips: Trip[];
  driver: DriverInfo | null;
  onClose: () => void;
  totalDistance: number;
}

export const DayTripDetails = (props: DayTripDetailsProps) => {
  const { date, trips, vehicle, driver, totalDistance } = props;

  const sortTrips = (a: Trip, b: Trip) => {
    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
  };

  return (
    <StyledDayTripDetailsContainer>
      <StyledFlexContainer>
        <Spacer x={1.5} />
        <StyledColumn>
          <Spacer y={1.5} />
          <StyledTitle>
            <TextCapitalized>
              {date && dateFormatterWeekDayDayMonthYear(date)}
            </TextCapitalized>
            <StyledAroundCloseIcon onClick={props.onClose}>
              <ClearRound />
            </StyledAroundCloseIcon>
          </StyledTitle>
          <Spacer y={1.5} />
          <StyledContent>
            {vehicle && (
              <>
                <StyledVehicleCard>
                  <VehicleCard
                    registrationNumber={vehicle.registrationNumber}
                    totalDistance={totalDistance}
                  />
                </StyledVehicleCard>
                <Spacer x={1.5} />
              </>
            )}
            {trips.length !== 0 && (
              <StyledColumn>
                {/* <HoursBarTrip
                  lastTripEndDate={trips[trips.length - 1].endDate}
                />
                <Spacer y={1.5} /> */}
                <StyledOverflow>
                  {trips.sort(sortTrips).map((t, index) => {
                    const tripDisplay = {
                      distance: t.distance
                        ? Number(t.distance.toFixed(1))
                        : null,
                      duration: t.secondsDuration
                        ? Math.round(t.secondsDuration / 60)
                        : null,
                      distanceVariation: t.distanceVariation
                        ? Number(t.distanceVariation.toFixed(1))
                        : null,
                      distanceVariationPercent: t.distanceVariationPercent,
                      durationVariation: t.durationVariation
                        ? Math.round(t.durationVariation / 60)
                        : t.durationVariation,
                      durationVariationPercent: t.durationVariationPercent,
                      ecoDrivingScore: t.ecoDrivingScore
                        ? Number(t.ecoDrivingScore.toFixed(0))
                        : null,

                      driver,
                      drivingEvents: {
                        smoothnessScore: t.smoothnessScore
                          ? Number(t.smoothnessScore.toFixed(0))
                          : null,
                        supSpeedScore: t.supSpeedScore
                          ? Number(t.supSpeedScore.toFixed(0))
                          : null,
                      },
                    };
                    return (
                      <div key={index + "dayTrip"}>
                        {index !== 0 && <StyledLineGrey />}
                        <StyledFlex>
                          <DatesBarTrip
                            startDate={t.startDate}
                            endDate={t.endDate}
                          />
                          <Spacer x={1.5} />
                          <TripDetails tripDisplay={tripDisplay} />
                        </StyledFlex>
                      </div>
                    );
                  })}
                </StyledOverflow>
              </StyledColumn>
            )}
          </StyledContent>
          <Spacer y={1.5} />
        </StyledColumn>
        <Spacer x={1.5} />
      </StyledFlexContainer>
    </StyledDayTripDetailsContainer>
  );
};

const StyledAroundCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  &:hover {
    background-color: ${colors["colors/surfaces/background/background_level1"]};
  }
  & svg {
    width: 1.75rem;
    height: 1.75rem;
    fill: ${colors["colors/text/darkGrey"]};
  }
`;

export interface TripDetailsProps {
  tripDisplay: TripOptionsDisplay;
}
const tripDetailsOptionsDict = ["distance", "duration", "ecoDrivingScore"];

const drivingEventsDict = ["smoothnessScore", "supSpeedScore"];

export const TripDetails = (props: TripDetailsProps) => {
  const { t } = useTranslation();
  const { tripDisplay } = props;
  const { driver } = props.tripDisplay;

  const hasBadSmoothnessScore = !!(
    tripDisplay.drivingEvents.smoothnessScore &&
    tripDisplay.drivingEvents.smoothnessScore < 80
  );
  const hasBadSupSpeedScore = !!(
    tripDisplay.drivingEvents.supSpeedScore &&
    tripDisplay.drivingEvents.supSpeedScore < 75
  );
  const displayDrivingEvents = hasBadSmoothnessScore || hasBadSupSpeedScore;

  return (
    <StyledTripContainer>
      <Spacer x={1.5} />
      <StyledColumn>
        {driver && (
          <>
            <Spacer y={1.5} />
            <StyledRowCenter>
              <TextCapitalized>
                <Avatar
                  size="M"
                  color="mint"
                  firstName={driver.firstName}
                  lastName={driver.lastName}
                />
              </TextCapitalized>
              <Spacer x={0.5} />
              {driver.firstName + " " + driver.lastName}
            </StyledRowCenter>
          </>
        )}
        <Spacer y={1.5} />
        <StyledFlex>
          {tripDetailsOptionsDict.map((option, index) => {
            const tripDetailsValue = tripDisplay[
              option as keyof TripOptionsDisplay
            ] as string | number;

            return (
              <div key={index + "trip"}>
                {tripDisplay[option as keyof TripOptionsDisplay] !== null && (
                  <StyledFlex>
                    {index !== 0 && (
                      <Spacer x={option.includes("Variation") ? 0.5 : 1.5} />
                    )}
                    <StyledTripDetail>
                      <StyledOptionTitle
                        $isVariation={option.includes("Variation")}
                      >
                        <TextCapitalized>
                          {t(`trips.details.${option}.title`)}
                        </TextCapitalized>
                      </StyledOptionTitle>
                      <Spacer y={0.5} />
                      <Badge
                        text={
                          <StyledFlex>
                            {tripDetailsValue}
                            <Spacer x={0.25} />
                            {t(`trips.details.${option}.units`)}
                          </StyledFlex>
                        }
                        backgroundColor={getBadgeColors(
                          option,
                          tripDetailsValue,
                          tripDisplay,
                        )}
                        textColor={colors["colors/text/black"]}
                        typo={typographies["Body/XS"]}
                      />
                    </StyledTripDetail>
                  </StyledFlex>
                )}
              </div>
            );
          })}
          {displayDrivingEvents && (
            <>
              <Spacer x={1.5} />
              <StyledFlex>
                <StyledTripDetail>
                  <StyledOptionTitle $isVariation={false}>
                    <TextCapitalized>
                      {t(`trips.details.drivingEvents.title`)}
                    </TextCapitalized>
                  </StyledOptionTitle>
                  <Spacer y={0.5} />
                  <StyledFlex>
                    {drivingEventsDict.map((event, index) => {
                      if (event === "smoothnessScore" && !hasBadSmoothnessScore)
                        return;
                      if (event === "supSpeedScore" && !hasBadSupSpeedScore)
                        return;

                      const eventValue = tripDisplay.drivingEvents[
                        event as keyof DrivingEventsType
                      ] as number;

                      return (
                        <StyledFlex key={index + "events"}>
                          {index !== 0 && hasBadSmoothnessScore && (
                            <Spacer x={0.5} />
                          )}
                          <Badge
                            text={
                              <StyledFlex>
                                <TextCapitalized>
                                  {t(`trips.details.drivingEvents.${event}`)}
                                </TextCapitalized>
                              </StyledFlex>
                            }
                            backgroundColor={getBadgeColors(
                              event,
                              eventValue,
                              tripDisplay,
                            )}
                            textColor={colors["colors/text/black"]}
                            typo={typographies["Body/XS"]}
                            tooltip={
                              <TooltipDS
                                id={index + "events"}
                                place={"bottom"}
                                content={t(
                                  `trips.details.drivingEvents.${event}.tooltip`,
                                )}
                              />
                            }
                          />
                        </StyledFlex>
                      );
                    })}
                  </StyledFlex>
                </StyledTripDetail>
              </StyledFlex>
            </>
          )}
        </StyledFlex>
        <Spacer y={1.5} />
      </StyledColumn>
      <Spacer x={1.5} />
    </StyledTripContainer>
  );
};

export interface DatesBarTripProps {
  startDate: string;
  endDate: string;
}

export interface HoursBarTripProps {
  lastTripEndDate: string;
}

export const HoursBarTrip = (props: HoursBarTripProps) => {
  console.log(props.lastTripEndDate);
  //TODO IF END NOT ON THE SAME DAY, ADD HOURS OF THE NEXT DAY
  const hours = Array.from({ length: 24 }, (_, m) => m);
  return (
    <StyledHoursBar>
      {hours.map((h, index) => (
        <StyledHour key={h}>
          {index === 0 && <Spacer x={1.25} />}
          <Spacer x={1} />
          {h + " H"}
          <Spacer x={1} />
        </StyledHour>
      ))}
    </StyledHoursBar>
  );
};

const StyledHoursBar = styled.div`
  display: flex;
  border: 0.125rem solid ${colors["colors/borders/cells/cells"]};
  color: ${colors["colors/text/lightGrey"]};
  ${typographies["Body/XXS"]};
  border-radius: 0.5rem;
  width: 80%;
  height: 2rem;
  overflow-x: auto;
  align-items: center;
  white-space: nowrap;
`;

const StyledHour = styled.div`
  display: flex;
`;

export const DatesBarTrip = (props: DatesBarTripProps) => {
  return (
    <>
      <StyledDates>
        <div>
          {dateFormatterHourMinute(new Date(props.startDate)).replaceAll(
            ":",
            "h",
          )}
        </div>
        <div>
          {dateFormatterHourMinute(new Date(props.endDate)).replaceAll(
            ":",
            "h",
          )}
        </div>
      </StyledDates>
      <Spacer x={0.5} />
      <StyledBarDates>
        <StyledRound />
        <StyledLineGreen />
        <StyledRound />
      </StyledBarDates>
    </>
  );
};

const StyledDates = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 3.25rem;
  justify-content: space-between;
`;

const StyledLineGreen = styled.div`
  height: 100%;
  border-right: 0.75rem solid
    ${colors["colors/system/success/success_veryLight"]};
`;

const StyledRound = styled.div`
  min-height: 0.75rem;
  min-width: 0.75rem;
  border-radius: 0.75rem;
  background: ${colors["colors/system/success/success_normal"]};
`;

const StyledBarDates = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors["colors/surfaces/background/background_level1"]};
`;

const StyledLineGrey = styled.div`
  height: 4rem;
  width: 4.5rem;
  border-right: 0.75rem solid
    ${colors["colors/surfaces/background/background_level1"]};
`;

const getEcoDrivingColorPerPercentage = (value: number) =>
  value < 35
    ? colors["colors/system/error/error_veryLight"]
    : value > 75
      ? colors["colors/system/success/success_veryLight"]
      : colors["colors/system/warning/warning_veryLight"];

const getSmoothnessScoreColorPerPercentage = (value: number) =>
  value > 40
    ? colors["colors/system/warning/warning_veryLight"]
    : colors["colors/system/error/error_veryLight"];

const getSupSpeedScoreColorPerPercentage = (value: number) =>
  value > 30
    ? colors["colors/system/warning/warning_veryLight"]
    : colors["colors/system/error/error_veryLight"];

const getVariationColor = (percent: number) =>
  percent > LIMIT_VARIATION_PERCENTAGE
    ? colors["colors/system/error/error_light"]
    : colors[`colors/system/success/success_veryLight`];

const getBadgeColors = (
  key: string,
  value: number | string | null,
  tripDetails: TripOptionsDisplay,
): string => {
  if (value === null) return "";
  switch (key) {
    case "ecoDrivingScore":
      return getEcoDrivingColorPerPercentage(value as number);
    case "smoothnessScore":
      return getSmoothnessScoreColorPerPercentage(value as number);
    case "supSpeedScore":
      return getSupSpeedScoreColorPerPercentage(value as number);
    case "durationVariation":
      return getVariationColor(tripDetails.durationVariationPercent || 0);
    case "distanceVariation":
      return getVariationColor(tripDetails.distanceVariationPercent || 0);
    default:
      return colors["colors/system/informative/informative_veryLight"];
  }
};

const StyledFlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledVehicleCard = styled.div`
  min-width: fit-content;
  max-width: 100%;
`;

const StyledContent = styled.div`
  display: flex;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledDayTripDetailsContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 100%;
  ${typographies["Body/S"]};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/input/default"]};
  overflow: auto;
`;

const StyledOverflow = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledTripContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors["colors/button/secondary/hover"]};
  border-radius: 0.5rem;
  overflow: auto;
`;

const StyledTripDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  ${typographies["Header/H2"]};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledOptionTitle = styled.div<{ $isVariation: boolean }>`
  color: ${({ $isVariation }) =>
    !$isVariation
      ? colors["colors/text/black"]
      : colors["colors/text/lightGrey"]};
`;

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
