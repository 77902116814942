import {
  ButtonDS,
  CheckBox,
  DialogModalDS,
  NumericInputDS,
  PageDS,
  RefreshOutlined,
  SelectFuel,
  Spacer,
  ToastContainer,
  dateFormatterDayMonthYearAndHourMinute2Digits,
  triggerToast,
} from "@qivia/ui";
import { TextCapitalized } from "@qivia/ui/src/designSystem/components/text/TextCapitalized";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryType, VerificationStatusType } from "./supportingDocumentsApi";
import {
  getSupportingDocumentAsync,
  getTransactionsForVerificationAsync,
  selectGetSupportingDocument,
  selectIsSupportingDocumentPdf,
  selectSupportingDocumentDataMessageError,
  selectTransactionsForVerificationList,
  selectUpdateSupportingDocumentStatus,
  selectUpdateTransactionsCategoryStatus,
  supportingDocumentsSlice,
  supportingDocumentsVerificationInProgressAsync,
  updateSupportingDocumentDataAsync,
  updateTransactionCategory,
} from "./supportingDocumentsSlice";
import { selectAdminUser } from "./homeSlice";
import { checkFuelType } from "./lib";
import {
  selectCreateMerchantGroupStatus,
  selectMerchantGroupCreated,
} from "./authorizationSlice";
import { AuthorizationModal } from "./modals/authorizationModal";
import { selectCompaniesList } from "./companiesSlice";

type SupportingDocumentRouteParams = { id: string };

interface ComponentRotateProps {
  readonly $rotate: number;
}

export const SupportingDocument = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<SupportingDocumentRouteParams>();
  const adminUser = useAppSelector(selectAdminUser);
  const [isChangeCategoryDialogVisible, setIsChangeCategoryDialogVisible] =
    useState(false);
  const [newCategory, setNewCategory] = useState<CategoryType | null>();
  const [
    isCreateMerchantNameModalVisible,
    setIsCreateMerchantNameModalVisible,
  ] = useState(false);

  const currentSupportingDocument = useAppSelector(selectGetSupportingDocument);
  const isCurrentSupportingDocumentPdf = useAppSelector(
    selectIsSupportingDocumentPdf,
  );
  const transactionsForVerificationList = useAppSelector(
    selectTransactionsForVerificationList,
  );

  const updateSupportingDocumentDataMessageError = useAppSelector(
    selectSupportingDocumentDataMessageError,
  );
  const updateSupportingDocumentStatus = useAppSelector(
    selectUpdateSupportingDocumentStatus,
  );
  const updateTransactionCategoryAsyncStatus = useAppSelector(
    selectUpdateTransactionsCategoryStatus,
  );
  const createMerchantGroupStatus = useAppSelector(
    selectCreateMerchantGroupStatus,
  );
  const merchantGroupCreated = useAppSelector(selectMerchantGroupCreated);

  const companiesList = useAppSelector(selectCompaniesList);

  useEffect(() => {
    if (updateTransactionCategoryAsyncStatus === "success") {
      void dispatch(getTransactionsForVerificationAsync());
      dispatch(
        supportingDocumentsSlice.actions.resetUpdateTransactionCategoryAsyncStatus(),
      );
    }
  }, [dispatch, updateTransactionCategoryAsyncStatus]);

  const transaction = useMemo(
    () =>
      transactionsForVerificationList.find((transaction) => {
        return transaction.uuid === params.id;
      }),
    [params.id, transactionsForVerificationList],
  );

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatusType | null>(null);
  const [quantity, setQuantity] = useState<number | "">(
    transaction?.quantity ?? "",
  );
  const [pricePerLiter, setPricePerLiter] = useState<number | "">(
    transaction?.pricePerLiter ?? "",
  );
  const [mileage, setMileage] = useState<number | "">(
    transaction?.mileage ?? "",
  );
  const [fuel, setFuel] = useState<string | null>(transaction?.fuel ?? null);

  const [receiptRotDeg, setReceiptRotDeg] = useState<number>(0);

  const [companyName, setCompanyName] = useState<string>("");

  useEffect(() => {
    if (transaction) {
      const company = companiesList.find((c) => c.uuid === transaction.company);
      if (company) setCompanyName(company.name);
    }
  }, [companiesList, transaction]);

  const isFuel = useMemo(() => {
    return (
      transaction &&
      (transaction.category === "FUEL_AUTOMATON" ||
        transaction.category === "SERVICE_STATION")
    );
  }, [transaction]);

  const isSameFuelTypeAsVehicle = useMemo(() => {
    if (!transaction?.vehicleFuel || !fuel) return true;
    return checkFuelType(fuel, transaction.vehicleFuel);
  }, [fuel, transaction]);

  const resetValue = useCallback(() => {
    setQuantity("");
    setPricePerLiter("");
    setFuel(null);
    setMileage("");
    setVerificationStatus(null);
  }, []);

  const isCurrentSupportingDocumentDataValidForSubmit = useCallback(() => {
    const isFullData = quantity && pricePerLiter && fuel;
    if (isFullData && verificationStatus) {
      return false;
    }
    return isFullData || !!verificationStatus;
  }, [fuel, pricePerLiter, quantity, verificationStatus]);

  const handleOpenChangeCategoryDialog = useCallback(
    (newCategory: CategoryType) => {
      setNewCategory(newCategory);
      setIsChangeCategoryDialogVisible(true);
    },
    [],
  );

  const handleCloseChangeCategoryDialog = useCallback(() => {
    setIsChangeCategoryDialogVisible(false);
  }, []);

  const handleOpenCreateMerchantNameModal = useCallback(() => {
    setIsCreateMerchantNameModalVisible(true);
  }, []);

  const handleCloseCreateMerchantNameModal = useCallback(() => {
    setIsCreateMerchantNameModalVisible(false);
  }, []);

  useEffect(() => {
    if (!transaction && transactionsForVerificationList.length > 0) {
      navigate(
        `/home/supporting_documents/${transactionsForVerificationList[0].uuid}`,
      );
    }
  }, [navigate, transaction, transactionsForVerificationList]);

  useEffect(() => {
    if (params.id === undefined) {
      return navigate("/home/supporting_documents");
    }
    void dispatch(getSupportingDocumentAsync(params.id));
  }, [dispatch, navigate, params.id]);

  useEffect(() => {
    if (updateSupportingDocumentDataMessageError)
      triggerToast(t("supportingDocument.errorMessage"), "error");
  }, [t, updateSupportingDocumentDataMessageError]);

  useEffect(() => {
    if (updateSupportingDocumentStatus === "success") {
      triggerToast(t("supportingDocument.update.success") || "", "valid");
    } else if (updateSupportingDocumentStatus === "failed") {
      triggerToast(t("supportingDocument.update.failure") || "", "error");
    }
  }, [updateSupportingDocumentStatus, t, dispatch]);

  useEffect(() => {
    if (createMerchantGroupStatus === "success") {
      triggerToast(
        t("authorization.createMerchantGroup.success") || "",
        "valid",
      );
    } else if (createMerchantGroupStatus === "failed") {
      triggerToast(
        t("authorization.createMerchantGroup.failure") || "",
        "error",
      );
    }
  }, [createMerchantGroupStatus, t, dispatch]);

  useEffect(() => {
    if (updateTransactionCategoryAsyncStatus === "success") {
      triggerToast(
        t("supportingDocuments.updateCategory.success") || "",
        "valid",
      );
    } else if (updateTransactionCategoryAsyncStatus === "failed") {
      triggerToast(
        t("supportingDocuments.updateCategory.failure") || "",
        "error",
      );
    }
    setIsChangeCategoryDialogVisible(false);
  }, [updateTransactionCategoryAsyncStatus, t, dispatch]);

  const close = useCallback(() => {
    navigate("/home/supporting_documents");
  }, [navigate]);

  const submit = useCallback(() => {
    if (!currentSupportingDocument) {
      return;
    }
    if (!isCurrentSupportingDocumentDataValidForSubmit()) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      void dispatch(
        updateSupportingDocumentDataAsync({
          quantity: quantity === "" ? null : quantity,
          pricePerLiter: pricePerLiter === "" ? null : pricePerLiter,
          fuel,
          verificationStatus: verificationStatus ?? "VERIFIED",
          supportingDocumentUuid: currentSupportingDocument.uuid,
          mileage: mileage || null,
        }),
      );
      void dispatch(
        supportingDocumentsVerificationInProgressAsync({
          userName: adminUser?.name ?? "",
        }),
      );
      resetValue();
    }
  }, [
    adminUser?.name,
    currentSupportingDocument,
    dispatch,
    fuel,
    isCurrentSupportingDocumentDataValidForSubmit,
    pricePerLiter,
    quantity,
    resetValue,
    verificationStatus,
    mileage,
  ]);

  const transactionMerchantGroup =
    transaction?.merchantGroup || merchantGroupCreated;

  const handleChangeTransactionCategoryToNewCategory = useCallback(() => {
    if (transaction?.uuid && newCategory) {
      void dispatch(
        updateTransactionCategory({
          transactionUuid: transaction.uuid,
          newCategory,
        }),
      );
    }
  }, [dispatch, transaction, newCategory]);

  if (!currentSupportingDocument || !transaction) {
    return;
  }
  return (
    <PageDS
      withoutSupport={true}
      toaster={<ToastContainer />}
      title={t("supportingDocuments.title")}
      cta={{
        isDisabled: !isCurrentSupportingDocumentDataValidForSubmit(),
        title: t("supportingDocument.submit"),
        action: submit,
      }}
      titleElement={{
        closeButtonAction: close,
      }}
    >
      <StyledContainer>
        <DialogModalDS
          visible={isChangeCategoryDialogVisible}
          onClose={handleCloseChangeCategoryDialog}
          title={t("supportingDocuments.dialog.title")}
          width={40}
          bottomButton={
            <ButtonDS
              text={t(`supportingDocuments.button.title.${newCategory}`)}
              format={"fill"}
              buttonType={"primary"}
              onClick={handleChangeTransactionCategoryToNewCategory}
            />
          }
        >
          <TextCapitalized>
            {t(`supportingDocuments.dialog.description.${newCategory}`)}
          </TextCapitalized>
        </DialogModalDS>
        <AuthorizationModal
          visible={isCreateMerchantNameModalVisible}
          onCloseModal={handleCloseCreateMerchantNameModal}
          merchantName={transaction.merchantName}
          authorizationId={transaction.authorizationId}
        />
        <StyledLeftColumn>
          <StyledRow>
            <StyledCategory>
              <StyledBold>
                <TextCapitalized>
                  {t("supportingDocument.category")}
                </TextCapitalized>
              </StyledBold>
              <Spacer x={1} />
              {t(`category.${transaction.category}`)}
            </StyledCategory>
            <Spacer x={3} />
            {transaction.category === "SERVICE_STATION" && (
              <>
                <ButtonDS
                  onClick={() => handleOpenChangeCategoryDialog("WASH")}
                  text={t("supportingDocuments.button.title.WASH")}
                  format="hug"
                  buttonType="primary"
                />
                <Spacer x={1} />
                <ButtonDS
                  onClick={() => handleOpenChangeCategoryDialog("OTHER")}
                  text={t("supportingDocuments.button.title.OTHER")}
                  format="hug"
                  buttonType="primary"
                />
              </>
            )}
            {transaction.category === "TOLL" && (
              <ButtonDS
                onClick={() => handleOpenChangeCategoryDialog("PARKING")}
                text={t("supportingDocuments.button.title.PARKING")}
                format="hug"
                buttonType="primary"
              />
            )}
          </StyledRow>
          <Spacer y={2} />
          <StyledMerchantName>
            <StyledBold>
              <TextCapitalized>
                {t("supportingDocument.merchantName")}
              </TextCapitalized>
            </StyledBold>
            <Spacer x={1} />
            {transaction.merchantName}
            {transactionMerchantGroup ? (
              <>
                <Spacer x={3} />
                <StyledBold>
                  <TextCapitalized>
                    {t("supportingDocument.merchantGroup")}
                  </TextCapitalized>
                </StyledBold>
                <Spacer x={1} />
                {transactionMerchantGroup}
              </>
            ) : (
              isFuel && (
                <StyledRow>
                  <Spacer x={3} />
                  <ButtonDS
                    onClick={handleOpenCreateMerchantNameModal}
                    format="hug"
                    buttonType="primary"
                    height="2.5"
                    text={t("authorization.modal.add.merchantName")}
                  />
                </StyledRow>
              )
            )}
          </StyledMerchantName>
          <Spacer y={2} />
          <StyledRow>
            <StyledBold>
              <TextCapitalized>
                {t("supportingDocument.executionDate")}
              </TextCapitalized>
            </StyledBold>
            <Spacer x={1} />
            {dateFormatterDayMonthYearAndHourMinute2Digits(
              new Date(transaction.executionDate),
            )}
            <Spacer x={2} />
            <StyledBold>
              <TextCapitalized>
                {t("supportingDocument.merchantLocation")}
              </TextCapitalized>
            </StyledBold>
            <Spacer x={1} />
            {transaction.merchantCity}, {transaction.merchantCountry}
            <Spacer x={2} />
            <StyledBold>
              <TextCapitalized>
                {t("supportingDocument.companyName")}
              </TextCapitalized>
            </StyledBold>
            <Spacer x={1} />
            {companyName}
          </StyledRow>
          <Spacer y={2} />
          <StyledRow>
            <StyledBold>
              <TextCapitalized>
                {t("supportingDocument.amount")}
              </TextCapitalized>
            </StyledBold>
            <Spacer x={1} />
            {transaction.amount.value / 100 + " " + transaction.amount.currency}
            <Spacer x={4} />
            {quantity && pricePerLiter && (
              <>
                <Spacer y={1} />
                <StyledRowRed>
                  <TextCapitalized>
                    {t("supportingDocument.calcul")}
                  </TextCapitalized>
                  <Spacer x={0.5} />
                  {(quantity * pricePerLiter).toFixed(2)}
                </StyledRowRed>
              </>
            )}
          </StyledRow>
          <Spacer y={2} />
          {!isFuel ? (
            <CheckBox
              text={t("supportingDocument.verified")}
              onClick={() =>
                verificationStatus === "VERIFIED"
                  ? setVerificationStatus(null)
                  : setVerificationStatus("VERIFIED")
              }
              state={
                verificationStatus === "VERIFIED" ? "CHECKED" : "UNCHECKED"
              }
            />
          ) : (
            <>
              <TextCapitalized>
                {t("supportingDocument.fuel")}
                {transaction.vehicleFuel
                  ? " : (" + transaction.vehicleFuel + ")"
                  : ""}
              </TextCapitalized>
              <SelectFuel
                onlyFuel
                value={fuel}
                onChange={(value) => setFuel(value)}
                error={
                  !isSameFuelTypeAsVehicle && fuel
                    ? t("supportingDocuments.fuelTypeWarning")
                    : displayError && !fuel && !verificationStatus
                      ? t("supportingDocuments.emptySelect")
                      : undefined
                }
                menuListMaxHeight={15}
              />
              <NumericInputDS
                label={t("supportingDocument.quantity")}
                value={quantity}
                withoutSpacerBottom
                update={(value) => setQuantity(value)}
                floatNumber
                adornent={"L"}
                onKeyDown={(e) => e.key === "Enter" && submit()}
                error={
                  (displayError &&
                    quantity === "" &&
                    !verificationStatus &&
                    t("supportingDocuments.emptyInput")) ||
                  undefined
                }
              />
              <Spacer y={1} />
              <NumericInputDS
                label={t("supportingDocument.pricePerLiter")}
                value={pricePerLiter}
                withoutSpacerBottom
                update={(value) => setPricePerLiter(value)}
                floatNumber
                adornent={t("supportingDocument.currency")}
                onKeyDown={(e) => e.key === "Enter" && submit()}
                error={
                  (displayError &&
                    pricePerLiter === "" &&
                    !verificationStatus &&
                    t("supportingDocuments.emptyInput")) ||
                  (quantity < pricePerLiter &&
                    t("supportingDocuments.pricePerLiterSuperiorToQuantity")) ||
                  undefined
                }
              />
            </>
          )}
          <Spacer y={1} />
          <CheckBox
            text={t("supportingDocument.unreadable")}
            onClick={() =>
              verificationStatus === "UNREADABLE"
                ? setVerificationStatus(null)
                : setVerificationStatus("UNREADABLE")
            }
            state={
              verificationStatus === "UNREADABLE" ? "CHECKED" : "UNCHECKED"
            }
          />
          <Spacer y={1} />
          <CheckBox
            text={t("supportingDocument.missing")}
            onClick={() =>
              verificationStatus === "MISSING"
                ? setVerificationStatus(null)
                : setVerificationStatus("MISSING")
            }
            state={verificationStatus === "MISSING" ? "CHECKED" : "UNCHECKED"}
          />
          <Spacer y={1} />
          <CheckBox
            text={t("supportingDocument.invalid_document")}
            onClick={() =>
              verificationStatus === "INVALID_DOCUMENT"
                ? setVerificationStatus(null)
                : setVerificationStatus("INVALID_DOCUMENT")
            }
            state={
              verificationStatus === "INVALID_DOCUMENT"
                ? "CHECKED"
                : "UNCHECKED"
            }
          />
          <Spacer y={2} />
          {isFuel && (
            <NumericInputDS
              label={`${t("supportingDocument.mileage")} ${transaction.mileage ? t("supportingDocument.lastKnownValue") + " : (" + transaction.mileage + ")" : ""}`}
              value={mileage}
              update={(value) => setMileage(value)}
              onKeyDown={(e) => e.key === "Enter" && submit()}
              error={
                (displayError &&
                  (mileage || 0) < (transaction.mileage || 0) &&
                  t("supportingDocuments.badMileageValue")) ||
                undefined
              }
            />
          )}
        </StyledLeftColumn>
        <Spacer x={1} />
        <StyledRow>
          <ButtonDS
            onClick={() => {
              setReceiptRotDeg((prev) => (prev + 90) % 360);
            }}
            format="hug"
            buttonType="primary"
            singleIcon={{ icon: <RefreshOutlined />, size: "S" }}
          />
        </StyledRow>
        <Spacer y={1} />
        {isCurrentSupportingDocumentPdf ? (
          <StyledIframe>
            <iframe
              src={currentSupportingDocument.presignedUrl}
              title={currentSupportingDocument.fileName}
            />
          </StyledIframe>
        ) : (
          <StyledRightColumn>
            <StyledImage
              src={currentSupportingDocument.presignedUrl}
              $rotate={receiptRotDeg}
            />
          </StyledRightColumn>
        )}
      </StyledContainer>
    </PageDS>
  );
};

const StyledRowRed = styled.div`
  display: flex;
  color: red;
`;

const StyledRow = styled.div`
  display: flex;
`;

const StyledMerchantName = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCategory = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 7.5rem;
`;

const StyledBold = styled.div`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledLeftColumn = styled.div`
  max-width: 50%;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledImage = styled.img<ComponentRotateProps>`
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  transform: ${(props) => `rotate(${props.$rotate}deg)`};
`;

const StyledRightColumn = styled.div`
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  overflow: auto;
`;

const StyledIframe = styled.div`
  width: 100%;
  max-width: 50%;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
  },
`;
