import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { typographies } from "../../styles/figmaTypographies";
import Car from "../assets/CarGrey.png";
import { useTranslation } from "react-i18next";
import { TextCapitalized } from "./text/TextCapitalized";
import { Badge } from "./Tag/Badge";

export interface VehicleCardProps {
  registrationNumber: string | null;
  totalDistance: number;
}

export const VehicleCard = (props: VehicleCardProps) => {
  const { t } = useTranslation();

  const { registrationNumber, totalDistance } = props;

  return (
    <StyledVehicleCard>
      <Spacer x={1.5} />
      <StyledColumn>
        <Spacer y={1} />
        <StyledRegistrationNumber>
          <TextCapitalized>
            {t("trips.details.vehicleCard.trips")}
          </TextCapitalized>
          <Spacer x={0.5} />
          {registrationNumber}
          <Spacer x={0.5} />
          <StyledCenter>
            <StyledImg src={Car} alt="Car" />
          </StyledCenter>
        </StyledRegistrationNumber>
        <Spacer y={0.5} />
        <StyledDistance>
          <TextCapitalized>
            {t("trips.details.vehicleCard.distance")}
          </TextCapitalized>
          <Spacer x={0.5} />
          <Badge
            text={
              <StyledDistanceText>
                {totalDistance.toFixed(2) + " km"}
              </StyledDistanceText>
            }
            backgroundColor={
              colors["colors/system/informative/informative_veryLight"]
            }
            textColor={colors["colors/text/darkGrey"]}
          />
        </StyledDistance>
        <Spacer y={1} />
      </StyledColumn>
      <Spacer x={1.5} />
    </StyledVehicleCard>
  );
};

const StyledVehicleCard = styled.div`
  display: flex;
  background-color: ${colors["colors/button/secondary/hover"]};
  border-radius: 0.5rem;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledImg = styled.img`
  width: 2rem;
`;

const StyledCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledRegistrationNumber = styled.div`
  display: flex;
  ${typographies["Body/L"]};
  white-space: nowrap;
`;

const StyledDistance = styled.div`
  display: flex;
  ${typographies["Body/S"]};
  align-items: center;
`;

const StyledDistanceText = styled.div`
  ${typographies["Body/XXS"]};
`;
